body {
  margin: 0;
  padding: 0;
  overflow-y: scroll;
  /* Enable vertical scrolling for the body */
  background-color: #f0f0f0;
  /* Fallback color if background image doesn't load */
}

/* https://sp2-partner.sabpaisa.in/chirayu.png */
.background {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url('./assest/images/background-img10.png');
  z-index: -1;
  background-size: cover;
  background-repeat: round;

}


.rightimage {
  width: 75%;
  height: 100%;
  top: 82%;
  bottom: 0;
  left: 81px;
  right: 0;
}

button.link-style {
  background: none;
  color: blue;
  border: none;
  padding: 0;
  text-decoration: underline;
  cursor: pointer;
  font-family: inherit;
  font-size: .875em;
}

.background-overlay {
  /* Set the color and opacity of the overlay */
  background-color: rgba(0, 0, 0, 0.5);
  /* You can adjust the opacity value between 0 and 1 */
  /* Set the overlay to cover the entire screen */
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  /* Make sure the overlay is behind other content */
}


.padding_2px {
  padding: 2px !important;
}


.card {
  width: 38rem;
}


.border-1px {
  border: 1px solid black;
}

table {
  width: 100%;


}


/* Force vertical scrollbar to be always visible */

/* Style the scrollbar */
/* Webkit browsers (Chrome, Safari) */
::-webkit-scrollbar {
  width: 10px;
  /* Adjust the width as needed */
}

::-webkit-scrollbar-track {
  background-color: #f1f1f1;
  /* Change track color as needed */
}

::-webkit-scrollbar-thumb {
  background-color: #888;
  /* Change thumb color as needed */
}



.alert_msg_text {
  background-color: #ff3535;
  color: white;
  padding-top: 4px;
  padding-bottom: 4px;
  padding-left: 1px;
  padding-right: 1px;
}


/* Styles for devices with a minimum width of 481 pixels and a maximum width of 768 pixels (e.g., larger smartphones and small tablets) */
@media (max-width: 1023px) {
  .background {
    background-image: url("./assest/images/background-img10.png");
  }

  .right-side-details {
    border-top: 1px solid black;
    padding-top: 5px;
    padding-bottom: 5px;
  }

  .alert_msg_text {
    font-size: 18px;
  }

  /* Add your CSS styles for larger smartphones and small tablets here */
}

/* Styles for devices with a minimum width of 769 pixels and a maximum width of 1024 pixels (e.g., larger tablets) */
@media (min-width: 769px) and (max-width: 1024px) {
  /* Add your CSS styles for larger tablets here */
}

/* Styles for devices with a minimum width of 1025 pixels and a maximum width of 1440 pixels (e.g., laptops and small desktops) */
@media (min-width: 1025px) and (max-width: 1440px) {
  /* Add your CSS styles for laptops and small desktops here */
}

/* Styles for devices with a minimum width of 1441 pixels (e.g., large desktops and wide screens) */
@media (min-width: 1441px) {
  /* Add your CSS styles for large desktops and wide screens here */
}