//@import url("../src/assest/fonts/Sofia/sofia.css");
@import url("../src/assest/fonts/Poppins/poppins.css");

// $primaryColor: #29205d;
$primaryColor: #ED941A;

// $buttonPrimary:
// $buttonOnHover:
// $buttonDisable:

// paragraphFontSize
// headingFontSize

// fontFamily



// outer dashboard
// .headerBg {
//     background-color: $primaryColor;
// }




// --------------------------------------------------------------------------------- 

// inner dashboard 
// .sidebarBg{
//     background-color: $primaryColor  //;
// }

// .dashboardHeaderBg{
//     background-color:$primaryColor  //#130532;
// }




// --------------------------------------------------------------------------------------

.primary-color{
    background-color: $primaryColor !important;
}

.btn-primary-color{
    background-color: $primaryColor !important;
    color: white !important;
}

.text-primary-color{
    color: $primaryColor !important;
}


//font size

.font-15{
    font-size: 15px;
}

.font-14{
    font-size: 14px;
}

.font-13{
    font-size: 13px;
}

.font-12{
    font-size: 12px;
}

.h-60vh{
    height: 60vh;
}



// global tag

p{
    font-family: "Poppins-Regular";
    font-weight: 500;
    font-style: normal;

}

h1,h2,h3,h4,h5{
    font-family: "Poppins-Regular";
    font-weight: 500;
    font-style: normal;
}

a, span, label {
    font-family: "Poppins-Regular";
    font-weight: 500;
    font-style: normal
}

