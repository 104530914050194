.logo_hr{
    max-width: 63px;
    width: 100px;
}

.logo_pmjay{
    max-width: 88px;
    width: 100px;
}

.logo_ayush{
    max-width: 88px;
    width: 100px;
}



@media (max-width: 768px) {
    
    .logo_hr{
        max-width: 63px;
        width: 40px;
    }
    
    .logo_pmjay{
        max-width: 88px;
        width: 60px;
    }
    
    .logo_ayush{
        max-width: 88px;
        width: 60px;
    }
    
    /* Add your CSS styles for larger smartphones and small tablets here */
  }