body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

}

.zoomed-content {
  transform: scale(0.9); /* Apply a zoom of 90% */
  transform-origin: top left; /* Set the transformation origin to the top-left corner */
}


/* .background-image {

  background-image: url("../src/assest/images/background-img1.png");
background-color: #f0f0f0;
  background-repeat: round;
  background-size: cover;
  width: max-content;
  height: 100vh;
} */

/* .background-image-color{
  background-color: #ffb16d59;;
  background-size: cover;
  background-position: center;
  width: 100%;
  height: 100%;
} */

.background-image-right {

  /* background-image: url("../src/assest/images/background-img-right.png"); */
  /* background-size: cover;
  background-position: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1; */

  


}



.bg_left_image{
  
  background-image: url("../src/assest/images/left-panal-img.png");
  /* background-size: cover; */
  /* background-position: center center; */
  background-color: #f0f0f0;
  background-repeat: round;
  /* height: 100%; */
  background-size: cover;
  /* background-position: center; */
  /* position: fixed; */
  /* top: 0; */
  /* left: 0; */
  width: 100%;
  height: 100vh;
  /* z-index: -1; */
    
}


code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.logo-image-hr{
  width: 60%;
}

.logo-image{
  width: 60%;
}

.cursor_pointer {cursor: pointer;}

.suppport{position: absolute;
  top: 85vh;
  bottom: 0;
  right: 0;
  }
/* Default styles for all devices */

/* Styles for devices with a maximum width of 480 pixels (e.g., small smartphones) */
@media (max-width: 480px) {
  
.logo-image{
  max-width: 60px;
width: max-content;
}

  /* Add your CSS styles for small smartphones here */
}

