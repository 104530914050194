.eligible_valid{
    background-color: #7fd100;
}

.eligible_novalid{
    background-color: #ff0000;
}


.table_container{
    overflow: scroll;
    height: 50vh;
}