.card_header{

}

.card_h_back_button{
    /* flex:0.8, 0.8, auto  */
    /* padding: 1rem; */
    padding-left: 1rem;
    padding-right: 1rem;
    color: grey;
    font-size: 20px;

}

.card_h_heading{
    /* flex */
    flex: 0.8 0.8 auto !important;
}