.left_img {
    width: 75%;
    height: 75%;
}


.scoll_card {
    overflow: scroll;
    height: 69%;
    background-color:rgb(250, 248, 248);
}

.h_62vh{
    /* height: 62vh; */
}


.check_box{
    border: 2px solid black !important;
}

